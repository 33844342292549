import React, { useEffect, useRef, useState } from 'react';
import { Button, TextField, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const PauseCampaignModal = ({ isOpen, onClose,campaignId,onSubmit,onCancel }) => {

    const modalOverlayRef = useRef(null);
    const validationSchema = Yup.object().shape({
        forgetEmail: Yup.string().required('Username is required')
    });


    const formOptions = { resolver: yupResolver(validationSchema) };
    const {handleSubmit } = useForm(formOptions);



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalOverlayRef.current && !modalOverlayRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    const pause = () => {


        // axios.post('http://localhost:8000/api/v1/auth/sendVerificationCode',user)
        //     .then((response) => {
        //         if(response.status === 200){
        //             history.navigate('/verification-code')
        //         }
        //     })
        //     .catch((err) => {
        //         setMsg('Error in The Code');
        //     })
    };

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-overlay pause-campaign-modal" ref={modalOverlayRef}>
                <div className="modal-content forget-password-modal-content">
                    <div className="container forget-password-modal-container">
                        {
                            (campaignId?.is_connected === 0  || campaignId?.google_campaign?.is_connected === 0)?
                                <Typography className={'forget-password-modal-title-typography'}>
                                    Do you want to link this campaign with system
                                </Typography> :
                                <Typography className={'forget-password-modal-title-typography'}>
                                    Do you want to delink this campaign with system
                                </Typography>
                        }

                        <form onSubmit={handleSubmit()}>
                            <Button
                                type='submit'
                                onClick={() => {
                                    pause();
                                    onSubmit(); // Invoke the onSubmit callback function
                                }}
                                color='primary'
                                variant="contained"
                                className={'pause-campaign-submit-button'}
                                fullWidth>Yes</Button>
                            <Button
                                label="Cancel"

                                onClick={() => {
                                    onClose();
                                    onCancel(); // Invoke the onCancel callback function
                                }} // Call the onClose function to hide the modal
                                className="pause-campaign-cancel-button"
                            >No</Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PauseCampaignModal;