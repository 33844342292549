import React from 'react';



const CampaignRoutes = [

    {
        navLink: '/demoDatatable',
        component: React.lazy(() => import('../../../components/DemoDataTable/demoDatatable')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/campaign',
        component: React.lazy(() => import('../pages/campaign')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/ads',
        component: React.lazy(() => import('../pages/ads')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/email-marketing',
        component: React.lazy(() => import('../pages/email/email_campaigns')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/createCampaign',
        component: React.lazy(() => import('../pages/createCampaign')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/email-content',
        component: React.lazy(() => import('../pages/emailContent')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/push-content',
        component: React.lazy(() => import('../pages/pushContent')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/facebook-content',
        component: React.lazy(() => import('../pages/facebookContent')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/ad-set/:camp_id',
        component: React.lazy(() => import('../pages/adset')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/pmax-campaign',
        component: React.lazy(() => import('../pages/pMaxCampaign')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/shopping-campaign',
        component: React.lazy(() => import('../pages/shoppingCampaign')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/choose-customer',
        component: React.lazy(() => import('../pages/chooseCustomer')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/choose-customer-for-manager/:manager_id',
        component: React.lazy(() => import('../pages/chooseCustomerForManager')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/campaignDetail/:camp_id',
        component: React.lazy(() => import('../pages/campaignDetail')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/ads-campaign-detail/:camp_id',
        component: React.lazy(() => import('../pages/googleCampaignDetail')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/google-campaign-detail/:camp_id',
        component: React.lazy(() => import('../pages/googleCampaignDetailFromCampaign')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/campaign-recommendations/:camp_id',
        component: React.lazy(() => import('../pages/smart_advice/campaignRecommendation')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/customer-recommendations',
        component: React.lazy(() => import('../pages/smart_advice/customerRecommendation')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/recommendation-details/:camp_id/:recommendation_type',
        component: React.lazy(() => import('../pages/smart_advice/recommendationDetails')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/customer-recommendation-details/:recommendation_type',
        component: React.lazy(() => import('../pages/smart_advice/customerRecommendationDetails')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/ad-group/:camp_id/:adGroup_id',
        component: React.lazy(() => import('../pages/AdGroup')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/edit-asset-group/:assetGroup_id',
        component: React.lazy(() => import('../pages/asset_group/EditAssetGroup')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/show-asset-group/:assetGroup_id',
        component: React.lazy(() => import('../pages/asset_group/ShowAssetGroup')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/show-asset/:asset_id',
        component: React.lazy(() => import('../pages/asset/ShowAsset')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/asset-group/:camp_id',
        component: React.lazy(() => import('../pages/AssetGroup')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/smart-advices',
        component: React.lazy(() => import('../pages/smart_advice/smartAdvices')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/recommendations',
        component: React.lazy(() => import('../pages/smart_advice/recommendation/index')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/recommendations/create',
        component: React.lazy(() => import('../pages/smart_advice/recommendation/create')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/recommendations/edit/:category_id',
        component: React.lazy(() => import('../pages/smart_advice/recommendation/edit')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/recommendations-category',
        component: React.lazy(() => import('../pages/smart_advice/recommendation_category/index')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/recommendations-category/create',
        component: React.lazy(() => import('../pages/smart_advice/recommendation_category/create')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/recommendations-category/edit/:category_id',
        component: React.lazy(() => import('../pages/smart_advice/recommendation_category/edit')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/smart-advices-details/:recommendation_type',
        component: React.lazy(() => import('../pages/smart_advice/smartAdvicesDetails')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/configuration/general',
        component: React.lazy(() => import('../pages/configuration/GeneralConfiguration')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/account-setup',
        component: React.lazy(() => import('../pages/configuration/setupAccount')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },


/////////////////////content routes////////////////////
    {
        navLink: '/products',
        component: React.lazy(() => import('../pages/products/index')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/orders',
        component: React.lazy(() => import('../pages/orders/index')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/templates',
        component: React.lazy(() => import('../pages/content/template/index')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/templates/create',
        component: React.lazy(() => import('../pages/content/template/create')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/templates/edit/:template_id',
        component: React.lazy(() => import('../pages/content/template/edit')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },


/////////////////////analytics routes////////////////////
    {
        navLink: 'analytics/products',
        component: React.lazy(() => import('../pages/analytics/products/products')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: 'analytics/products_list',
        component: React.lazy(() => import('../pages/analytics/products/products_list')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: 'analytics/customers',
        component: React.lazy(() => import('../pages/analytics/customers/customers')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: 'analytics/customers_list',
        component: React.lazy(() => import('../pages/analytics/customers/customers_list')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },


//////////////Audiences routes
    {
        navLink: '/import-customer',
        component: React.lazy(() => import('../pages/audiences/customers/import_customer')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/customers',
        component: React.lazy(() => import('../pages/audiences/customers/index')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/customer-groups',
        component: React.lazy(() => import('../pages/audiences/customer_groups/index')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/customer-groups/create',
        component: React.lazy(() => import('../pages/audiences/customer_groups/create')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/customer-groups/edit/:group_id',
        component: React.lazy(() => import('../pages/audiences/customer_groups/edit')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },

    ////////////////////////////////////////

    {
        navLink: '/automation',
        component: React.lazy(() => import('../pages/automation/automation')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },

    {
        navLink: '/create-automation',
        component: React.lazy(() => import('../pages/automation/new_automation')),
        layout : true,
        authRequired: true,
        layout_file :'automation'
    },

    {
        navLink: '/edit-automation/:flow_id',
        component: React.lazy(() => import('../pages/automation/edit_automation')),
        layout : true,
        authRequired: true,
        layout_file :'automation'
    },
    {
        navLink: '/automation2',
        component: React.lazy(() => import('../pages/automation/new_automation')),
        layout : true,
        authRequired: true,
        layout_file :'automation'
    },
    ////////////////////////////////////////////

    {
        navLink: '/empty-types',
        component: React.lazy(() => import('../pages/empty/empty_type/index')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/empty-types/create',
        component: React.lazy(() => import('../pages/empty/empty_type/create')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/empty-types/edit/:type_id',
        component: React.lazy(() => import('../pages/empty/empty_type/edit')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/custom-campaigns',
        component: React.lazy(() => import('../pages/empty/empty_campaign/index')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/custom-campaigns/create',
        component: React.lazy(() => import('../pages/empty/empty_campaign/create')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/custom-campaigns/edit/:campaign_id',
        component: React.lazy(() => import('../pages/empty/empty_campaign/edit')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
    {
        navLink: '/custom-campaigns/detail/:campaign_id',
        component: React.lazy(() => import('../pages/empty/empty_campaign/details')),
        layout : true,
        authRequired: true,
        layout_file :'vertical'
    },
];

export default CampaignRoutes;